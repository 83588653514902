body {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.button_style {
  margin-left: 10px !important;
  margin-right: 10px;
}

.centerTable {
  margin: 0px auto;
}

h2 {
    color: #3f51b5;
}
